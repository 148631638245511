
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Response} from '@angular/http';
import { Observable } from 'rxjs';
import { GlobalSettings } from '../global/globalsettings'; 
import { GlobalService } from './global.service';

@Injectable()
export class AuditLogsService {
  constructor(private _http: Http,
    private _appService: GlobalService) {
   }

  add(model): Observable<any> {    
    let body = '[' + JSON.stringify(model) + ']';
    return this._http.post(GlobalSettings.BASE_API_ENDPOINT_TENANT + "/sync/postauditlogs", body,  this._appService.getRequestOptions()).pipe(
    map((response: Response) => response.json()),
    catchError(this._appService.handleErrorPromise),);
  }  

  get(logAppType: string, eventType: string, days: string): Observable<any> {
    return this._http.get(GlobalSettings.BASE_API_ENDPOINT_TENANT + "/sync/auditlogs" + "?logAppType=" + logAppType + "&eventType=" + eventType + "&days=" + days, this._appService.getRequestOptions()).pipe(
      map((response: Response) => response.json()),
      catchError(this._appService.handleErrorPromise),);
  }

  getEventTypeList() {  
    return this._http.get(GlobalSettings.BASE_API_ENDPOINT_TENANT + "/sync/geteventtype", this._appService.getRequestOptions()).pipe(
      map((response: Response) => response.json()),
      catchError(this._appService.handleErrorPromise),);
  }

  getExportFile(appType: string, eventType: string, startDate: string, endDate: string) {   
    return this._http.get(GlobalSettings.BASE_API_ENDPOINT_TENANT + "/sync/auditlogsfile" + "?logAppType=" + appType + "&eventType=" + eventType + "&startDate=" + startDate + "&endDate=" + endDate, this._appService.getRequestOptions()).pipe(
      map((response: Response) => response.json()),
      catchError(this._appService.handleErrorPromise),);
  }
  
}
