import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from './navbar.component';
import { NavbarService } from './navbar.service';
//import { translateDictModule } from '../../translateDict.module';
import { FormsModule } from '@angular/forms';

declare var $: any;

@NgModule({
    imports: [RouterModule, CommonModule, FormsModule],
    declarations: [ NavbarComponent ],
    exports: [NavbarComponent],
    providers: [
        NavbarService
    ]
})

export class NavbarModule {}
