import { AuthService } from '../../pages/login/authservice';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, NgZone } from '@angular/core';

@Component({
  selector: 'app-layout',
  templateUrl: './auth-layout.component.html'
})
export class AuthLayoutComponent {
  constructor(private _router: Router
    , private _authService: AuthService
    , private _zone: NgZone
    , private _activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this._authService.getToken();

    setTimeout(() => {
      this._zone.run(
        () => {
          if (window.localStorage.getItem('auth_key') != null) {
            this._router.navigate(['/dashboard'])
          } 
        }   
      );
    }, 200);
  }
}
