import { AuthService } from './pages/login/authservice';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserIdleService } from 'angular-user-idle'
import { GlobalSettings } from './shared/global/globalsettings';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(private _authService: AuthService,
    private userIdle: UserIdleService,
    private router: Router) { }

  canActivate(): boolean {

    if (this._authService.isOnline()) {

      this.userIdle.resetTimer();

      if (window.location.pathname == "/" || window.location.pathname == GlobalSettings.homePageUrl)
        return true;

      if (window.location.pathname != null && window.location.pathname != undefined &&
        window.location.pathname != "") {

        var path = window.location.pathname;

        var checkWithUserInfo = JSON.parse(window.localStorage.getItem("UserInfo"));

        if (checkWithUserInfo != null && checkWithUserInfo != undefined) {

          var menuList = checkWithUserInfo.RoleDTO.PermissionDataInGroupDTO.Menu;

          if (menuList != null && menuList.length > 0) {
            
            for (var i = 0; i < menuList.length; i++) {
              if (menuList[i].MenuPath == path) {
                return true;
              }
              else {
                for (var j = 0; j < menuList[i].SubMenu.length; j++) {
                  if (menuList[i].SubMenu[j].SubMenuPath == path) {
                    return true;
                  }
                }
              }
            }
            this.router.navigate([GlobalSettings.homePageUrl]);
            return true;
          }
          else {
            this.router.navigate([GlobalSettings.homePageUrl]);
            return true;
          }
        }
        else {
          this.router.navigate([GlobalSettings.homePageUrl]);
          return true;
        }
      }
      else {
        return true;
      }
    }
    this._authService.login();
    return false;
  }
}
