export enum MenuType {
    BRAND,
    LEFT,
    RIGHT
}

export interface RouteInfo {
    Id: string;
    path: string;
    title: string;
    menuType: MenuType;
    icon: string;
    Group: string;
    GroupIcon: string;
}
