import { MenuType, RouteInfo } from './sidebar.metadata';
var menuType = MenuType.LEFT;
export const ROUTES: RouteInfo[] = [

    {
      Id: '1',
      path: '/dashboard',
      title: 'DASHBOARD',
      icon: 'demo-icon icondashboard',
      menuType: menuType,
      Group: 'DASHBOARD',
      GroupIcon: '',

    },
    {
      Id: '54',
      path: '/asset',
      title: 'ASSET TYPES',
      icon: 'icon-picture icons',
      menuType: menuType,
      Group: 'MASTER',
      GroupIcon: 'dashboard',

    },
    {
      Id: '55',
      path: '/category',
      title: 'CATEGORIES',
      icon: '',
      menuType: menuType,
      Group: 'MASTER',
      GroupIcon: 'dashboard',

    },
    {
      Id: '57',
      path: '/devicetype',
      title: 'DEVICE TYPES',
      icon: '',
      menuType: menuType,
      Group: 'MASTER',
      GroupIcon: 'dashboard',

    },
    {
      Id: '58',
      path: '/language',
      title: 'LANGUAGES',
      icon: '',
      menuType: menuType,
      Group: 'MASTER',
      GroupIcon: 'dashboard',

    },
    {
      Id: '60',
      path: '/tenantmaster',
      title: 'CORE COMPONENTS',
      icon: '',
      menuType: menuType,
      Group: 'APPLICATION SETUP',
      GroupIcon: 'dashboard',

    },
    {
      Id: '56',
      path: '/config',
      title: 'APP CONFIGURATION',
      icon: '',
      menuType: menuType,
      Group: 'APPLICATION SETUP',
      GroupIcon: 'dashboard',

    },
    {
      Id: '59',
      path: '/resource',
      title: 'RESOURCES',
      icon: '',
      menuType: menuType,
      Group: 'APPLICATION SETUP',
      GroupIcon: 'dashboard',

    },
    {
      Id: '61',
      path: '/inspectionwidgets',
      title: 'WIDGETS',
      icon: '',
      menuType: menuType,
      Group: 'APPLICATION SETUP',
      GroupIcon: 'dashboard',

    },
    {
      Id: '49',
      path: '/inventory',
      title: 'INVENTORIES',
      icon: 'demo-icon iconinventory',
      menuType: menuType,
      Group: 'INVENTORIES',
      GroupIcon: '',

    },
    {
      Id: '50',
      path: '/formtype',
      title: 'FORM TYPES',
      icon: 'demo-icon iconform-type',
      menuType: menuType,
      Group: 'FORMS',
      GroupIcon: 'dashboard',

    },
    {
      Id: '66',
      path: '/inspectionforms',
      title: 'FORM VERSIONS',
      icon: 'demo-icon iconform',
      menuType: menuType,
      Group: 'FORMS',
      GroupIcon: 'dashboard',

    },
    {
      Id: '47',
      path: '/schedules',
      title: 'SCHEDULES',
      icon: 'demo-icon iconscheduled',
      menuType: menuType,
      Group: 'SCHEDULES',
      GroupIcon: '',

    },
    {
      Id: '48',
      path: '/inspection',
      title: 'INSPECTIONS',
      icon: 'lnr lnr-file-empty',
      menuType: menuType,
      Group: 'INSPECTIONS',
      GroupIcon: '',

    },
    {
      Id: '67',
      path: '/lookupkey',
      title: 'LOOKUP KEY',
      icon: 'material-icons',
      menuType: menuType,
      Group: 'LOOKUP',
      GroupIcon: 'filter_list',

    },
    {
      Id: '51',
      path: '/lookup',
      title: 'LOOKUP MASTER',
      icon: 'material-icons',
      menuType: menuType,
      Group: 'LOOKUP',
      GroupIcon: 'filter_list',

    },
    {
      Id: '65',
      path: '/lookuplevel',
      title: 'LOOKUP LEVELS',
      icon: 'material-icons',
      menuType: menuType,
      Group: 'LOOKUP',
      GroupIcon: 'filter_list',

    },
    {
      Id: '64',
      path: '/template',
      title: 'TEMPLATES',
      icon: 'material-icons',
      menuType: menuType,
      Group: 'TEMPLATES',
      GroupIcon: 'dashboard',

    }, 
    {
      Id: '73',
      path: '/reportmetadata',
      title: 'REPORT METADATA',
      icon: 'demo-icon iconinventory',
      menuType: menuType,
      Group: 'REPORTS',
      GroupIcon: '',

    }, 
    {
      Id: '52',
      path: '/notification',
      title: 'NOTIFICATIONS',
      icon: 'material-icons',
      menuType: menuType,
      Group: 'NOTIFICATIONS',
      GroupIcon: 'dashboard',

    },
    //{
    //  Id: '53',
    //  path: '/management',
    //  title: 'MANAGEMENT',
    //  icon: 'material-icons',
    //  menuType: menuType,
    //  Group: 'MANAGEMENT',
    //  GroupIcon: 'dashboard',
    //},
    {
      Id: '54',
      path: '/rolepermissions',
      title: 'Role Permissions',
      icon: 'material-icons',
      menuType: menuType,
      Group: 'MANAGEMENT',
      GroupIcon: 'dashboard',

    },
    {
      Id: '54',
      path: '/userrolemapping',
      title: 'User Role Mappings',
      icon: 'material-icons',
      menuType: menuType,
      Group: 'MANAGEMENT',
      GroupIcon: 'dashboard',

    },
    {
      Id: '55',
      path: '/userinventorymapping',
      title: 'User Inventory Mappings',
      icon: 'material-icons',
      menuType: menuType,
      Group: 'MANAGEMENT',
      GroupIcon: 'dashboard',

    },







    //{
    //  Id: '2',
    //  path: '/pages/timeline',
    //  title: 'Timeline Page',
    //  icon: 'material-icons',
    //  menuType: menuType,
    //  Group: 'Pages',
    //  GroupIcon: 'image',

    //},
    //{
    //  Id: '3',
    //  path: '/pages/user',
    //  title: 'User Page',
    //  icon: 'material-icons',
    //  menuType: menuType,
    //  Group: 'Pages',
    //  GroupIcon: 'image',

    //},
    //{
    //  Id: '24',
    //  path: '/pages/pricing',
    //  title: 'Pricing',
    //  icon: 'material-icons',
    //  menuType: menuType,
    //  Group: 'Pages',
    //  GroupIcon: 'image',

    //},
    //{
    //  Id: '25',
    //  path: '/pages/login',
    //  title: 'Login Page',
    //  icon: 'material-icons',
    //  menuType: menuType,
    //  Group: 'Pages',
    //  GroupIcon: 'image',

    //},
    //{
    //  Id: '26',
    //  path: '/pages/register',
    //  title: 'Register Page',
    //  icon: 'material-icons',
    //  menuType: menuType,
    //  Group: 'Pages',
    //  GroupIcon: 'image',

    //},
    //{
    //  Id: '27',
    //  path: '/pages/lock',
    //  title: 'Lock Screen Page',
    //  icon: 'material-icons',
    //  menuType: menuType,
    //  Group: 'Pages',
    //  GroupIcon: 'image',

    //},
    //{
    //  Id: '28',
    //  path: '/parameter/systemParameter',
    //  title: 'System Parameter',
    //  icon: 'pe-7s-plugin',
    //  menuType: menuType,
    //  Group: 'Parameter',
    //  GroupIcon: 'settings',

    //},
    //{
    //  Id: '29',
    //  path: '/parameter/applicationParameter',
    //  title: 'Application Parameter',
    //  icon: 'pe-7s-plugin',
    //  menuType: menuType,
    //  Group: 'Parameter',
    //  GroupIcon: 'settings',

    //},
    //{
    //  Id: '4',
    //  path: '/components/buttons',
    //  title: 'Buttons',
    //  icon: 'pe-7s-plugin',
    //  menuType: menuType,
    //  Group: 'Components',
    //  GroupIcon: 'apps',

    //},
    //{
    //  Id: '5',
    //  path: '/components/grid',
    //  title: 'Grid System',
    //  icon: 'pe-7s-plugin',
    //  menuType: menuType,
    //  Group: 'Components',
    //  GroupIcon: 'apps',

    //},
    //{
    //  Id: '6',
    //  path: '/components/panels',
    //  title: 'Panels',
    //  icon: 'pe-7s-plugin',
    //  menuType: menuType,
    //  Group: 'Components',
    //  GroupIcon: 'apps',

    //},
    //{
    //  Id: '7',
    //  path: '/components/sweet-alert',
    //  title: 'Sweet Alert',
    //  icon: 'pe-7s-plugin',
    //  menuType: menuType,
    //  Group: 'Components',
    //  GroupIcon: 'apps',

    //},
    //{
    //  Id: '8',
    //  path: '/components/notifications',
    //  title: 'Notifications',
    //  icon: 'pe-7s-plugin',
    //  menuType: menuType,
    //  Group: 'Components',
    //  GroupIcon: 'apps',

    //},
    //{
    //  Id: '9',
    //  path: '/components/icons',
    //  title: 'Icons',
    //  icon: 'pe-7s-plugin',
    //  menuType: menuType,
    //  Group: 'Components',
    //  GroupIcon: 'apps',

    //},
    //{
    //  Id: '10',
    //  path: '/components/typography',
    //  title: 'Typography',
    //  icon: 'pe-7s-plugin',
    //  menuType: menuType,
    //  Group: 'Components',
    //  GroupIcon: 'apps',

    //},
    //{
    //  Id: '11',
    //  path: '/forms/regular',
    //  title: 'Regular Forms',
    //  icon: 'pe-7s-note2',
    //  menuType: menuType,
    //  Group: 'Forms',
    //  GroupIcon: 'content_paste',

    //},
    //{
    //  Id: '12',
    //  path: '/forms/extended',
    //  title: 'Extended Forms',
    //  icon: 'pe-7s-note2',
    //  menuType: menuType,
    //  Group: 'Forms',
    //  GroupIcon: 'content_paste',

    //},
    //{
    //  Id: '13',
    //  path: '/forms/validation',
    //  title: 'Validation Forms',
    //  icon: 'pe-7s-note2',
    //  menuType: menuType,
    //  Group: 'Forms',
    //  GroupIcon: 'content_paste',

    //},
    //{
    //  Id: '14',
    //  path: '/forms/wizard',
    //  title: 'Wizard',
    //  icon: 'pe-7s-note2',
    //  menuType: menuType,
    //  Group: 'Forms',
    //  GroupIcon: 'content_paste',

    //},
    //{
    //  Id: '15',
    //  path: '/tables/regular',
    //  title: 'Regular Tables',
    //  icon: 'pe-7s-news-paper',
    //  menuType: menuType,
    //  Group: 'Tables',
    //  GroupIcon: 'grid_on',

    //},
    //{
    //  Id: '16',
    //  path: '/tables/extended',
    //  title: 'Extended Tables',
    //  icon: 'pe-7s-news-paper',
    //  menuType: menuType,
    //  Group: 'Tables',
    //  GroupIcon: 'grid_on',

    //},
    //{
    //  Id: '17',
    //  path: '/tables/datatables.net',
    //  title: 'DataTables.net',
    //  icon: 'pe-7s-news-paper',
    //  menuType: menuType,
    //  Group: 'Tables',
    //  GroupIcon: 'grid_on',

    //},
    //{
    //  Id: '18',
    //  path: '/maps/google',
    //  title: 'Google Maps',
    //  icon: 'pe-7s-map-marker',
    //  menuType: menuType,
    //  Group: 'Maps',
    //  GroupIcon: 'place',

    //},
    //{
    //  Id: '19',
    //  path: '/maps/fullscreen',
    //  title: 'Full Screen Map',
    //  icon: 'pe-7s-map-marker',
    //  menuType: menuType,
    //  Group: 'Maps',
    //  GroupIcon: 'place',

    //},
    //{
    //  Id: '20',
    //  path: '/maps/vector',
    //  title: 'Vector Map',
    //  icon: 'pe-7s-map-marker',
    //  menuType: menuType,
    //  Group: 'Maps',
    //  GroupIcon: 'place',

    //},
    //{
    //  Id: '21',
    //  path: '/widgets',
    //  title: 'Widgets',
    //  icon: 'material-icons',
    //  menuType: menuType,
    //  Group: 'Widgets',
    //  GroupIcon: 'widgets',

    //},
    //{
    //  Id: '22',
    //  path: '/charts',
    //  title: 'Charts',
    //  icon: 'material-icons',
    //  menuType: menuType,
    //  Group: 'Charts',
    //  GroupIcon: 'timeline',

    //},
    //{
    //  Id: '23',
    //  path: '/calendar',
    //  title: 'Calendar',
    //  icon: 'material-icons',
    //  menuType: menuType,
    //  Group: 'Calendar',
    //  GroupIcon: 'date_range',

    //} 
];
