import { Injectable } from '@angular/core';
import * as Msal from 'msal';
import { UserIdleService } from 'angular-user-idle'
import { environment } from 'environments/environment';
import { ApplicationInsightsService } from 'app/application-insights.service';

const CONFIG = environment.Settings;

@Injectable()
export class AuthService {

  public access_token = null;
  private app: any;
  public user;
  public isAuthenticated = false;

  constructor(private userIdle: UserIdleService, private applicationInsightsService: ApplicationInsightsService) {

    this.app = new Msal.UserAgentApplication(
      CONFIG.CLIENT_ID,
      null,
      (errorDesc, token, error, tokenType) => {
        // callback for login redirect
        if (error) {
          console.log(JSON.stringify(error));
          return;
        }
        if (token) {
          this.app.acquireTokenSilent(CONFIG.SCOPES)
        }
        console.log('Callback for login');
        // this.access_token = token;
      }, {
        cacheLocation: 'localStorage',
        redirectUri: CONFIG.REDIRECT_URI,
        navigateToLoginRequestUrl: false
      });

    //Start watching for user inactivity.
    this.userIdle.startWatching();

    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe(count => console.log(count));

    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => {
      this.userIdle.stopTimer();
      this.userIdle.startWatching();
      this.app.logout()
    });

  }

  public login() {

    return this.app.loginRedirect(CONFIG.SCOPES).then(

      idToken => {
        this.app.acquireTokenSilent(CONFIG.SCOPES).then(

          accessToken => {

            this.access_token = accessToken;
            console.log('ACCESS TOKEN: \n ' + this.access_token);
            this.user = this.app.getUser();
            this.isAuthenticated = true;
            this.applicationInsightsService.setUserId(this.user);
            window.localStorage.setItem('auth_key', accessToken);
            this.userIdle.startWatching();
          },
          error => {
            this.applicationInsightsService.clearUserId();
            this.app.acquireTokenPopup(CONFIG.SCOPES).then(accessToken => {
              console.log('Error acquiring the popup:\n' + error);
            });

          }
        );
      },
      error => {
        this.applicationInsightsService.clearUserId();
        console.log('Error during login:\n' + error);
      }
    );
  }

  public logout() {
    this.app.clearCacheForScope(localStorage.getItem('auth_key'));
    window.localStorage.removeItem("UserInfo");
    this.applicationInsightsService.clearUserId();
    this.app.logout();
  }

  public isOnline(): boolean {
    return this.app.getUser() != null;
  }

  public getCurrentLogin() {
    const user = this.app.getUser();
    return user;
  }

  public getName() {
    const user = this.app.getUser();
    if (user == null) { return null; }
    return user.name;
  }

  public getUniqueID() {
    const user = this.app.getUser();
    if (user == null) { return null; }
    return user.idToken.preferred_username;
  }

  public getToken() {
    return this.app.acquireTokenSilent(CONFIG.SCOPES).then(accessToken => {
      window.localStorage.setItem('auth_key', accessToken);
      this.userIdle.resetTimer();
      return accessToken;
    },
      error => {
        return this.app.acquireTokenSilent(CONFIG.SCOPES).then(accessToken => {
          window.localStorage.setItem('auth_key', accessToken);
          this.userIdle.resetTimer();
          return accessToken;
        },
          err => {
            console.error(err);
          });
      });
  }

  public checkUserLoggedIn() {
    var authKey = window.localStorage.getItem('auth_key');
    if (authKey != null && authKey != undefined) {
      return this.user;
    }
    else {
      return null;
    }
  }

}
