import { GlobalSettings,DateFormat,TimeFormat, CommonMethods } from '../../shared/global/globalsettings';
import { CustomDateTimesModel } from '../../model/Common/CustomDateTimesModel';

export class AuditLogsModel { 
  public TenantID: string;
  public LogID: number;
  public Logs: string;
  public LogDate: string;
  public LogAppType: string;
  public AppVersion: string;
  public InspectorID: string;
  public DeviceID: string;
  public DeviceName: string;
  public Manufacturer: string;
  public Model: string;
  public OSVersion: string;
  public Type: string;
  public BrowserInfo: string;
  public JavaScriptEnabled: boolean;
  public CookiesEnabled: boolean;
  public LoginSessionID: string;
  public LoginTime: string;
  private logDateTime: CustomDateTimesModel = new CustomDateTimesModel();

 constructor() {
        this.TenantID = '';
        this.LogID = 0;
   this.Logs = '';
   this.LogDate = CommonMethods.getCurrentDateInUTC().toString();
        this.LogAppType = 'admin';
        this.AppVersion = this.getAppVersion();
        this.InspectorID = this.getLoggedInUser();
        this.DeviceID = null;
        this.DeviceName = null;
        this.Manufacturer = null;
        this.Model = null;
        this.OSVersion = this.getBrowserOS();
        this.Type = '';
        this.BrowserInfo = this.getBrowserInfo();
        this.JavaScriptEnabled = this.checkJavascriptEnabled();
        this.CookiesEnabled = this.checkCookiesEnabled();
        this.LoginSessionID = this.getLogginSessionID();
   this.LoginTime = CommonMethods.getCurrentDateInUTC().toString();
  }

 getLocalDateWithFormat() {
   var now = new Date();
   var mm = now.getMonth() + 1;
   var dd = now.getDate();
   var yy = now.getFullYear();
   var formattedDate = null;

   switch (GlobalSettings.DATE_FORMAT) {    
     case DateFormat.DDMMYYYY:
     case DateFormat.ddMMyyyy:
       formattedDate = dd + '/' + mm + '/' + yy;
       return formattedDate;
     case DateFormat.MMddyyyy:
     case DateFormat.MMDDYYYY:
       formattedDate = mm + '/' + dd + '/' + yy;
       return formattedDate;
     default:
       return 0;
   }
 }

   getAppVersion()
   {
     return localStorage.getItem('AppVersion');
 }

   getBrowserInfo() {
    return window.navigator.appCodeName +' '+ window.navigator.product +' '+ window.navigator.appVersion;
   }

   checkCookiesEnabled() {
     return window.navigator.cookieEnabled;
   }

   checkJavascriptEnabled() {
     return window.navigator.javaEnabled();
   }

   getBrowserOS() {
     return window.navigator.platform;
   }

   getLoggedInUser() {
     return localStorage.getItem('LoggedInUser');
   }

   getLogginSessionID() {
     return null;
   }

  getLocalTimeWithFormat() {
    var formattedTime = null;
     switch (GlobalSettings.TIME_FORMAT) {
       case TimeFormat.HHmm:
         formattedTime = this.DisplayCurrentTimeWith24h();
         return formattedTime;
       case TimeFormat.hhmma:
         formattedTime = this.DisplayCurrentTimeWithAMPM();
         return formattedTime;
       default:
         return 0;
     }
   }

  DisplayCurrentTimeWithAMPM() {
  var date = new Date();
  var hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
  var hours1 = hours < 10 ? "0" + hours : hours;
  var am_pm = date.getHours() >= 12 ? "PM" : "AM";  
  var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
  //var seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  var time = hours1 + ":" + minutes + " " + am_pm;  //+ ":" + seconds +
  return time;
}

  DisplayCurrentTimeWith24h() {
    var date = new Date();
    var hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    //var seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    var time = hours + ":" + minutes; // + ":" + seconds;    
    return time;
  }

 }
 
