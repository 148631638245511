import { Component } from '@angular/core';
import { GlobalSettings } from '../global/globalsettings';

@Component({
  moduleId: module.id,
  selector: 'footer-cmp',
  templateUrl: 'footer.component.html'
})

export class FooterComponent {
  test: Date = new Date();
  appName = GlobalSettings.TITLE;
  Version = GlobalSettings.VERSION;
}
