import { Component, OnInit, AfterViewInit, AfterViewChecked, AfterContentInit } from '@angular/core';
import { ROUTES } from './sidebar-routes.config';
import { MenuType, RouteInfo } from './sidebar.metadata';
import { GlobalSettings, AuditLogAction, AuditLogType, MainMenus } from '../shared/global/globalsettings';
import { Observable, Subscription } from "rxjs";
import { AuthService } from '../pages/login/authservice';
import { AuditLogsService } from '../shared/service/auditLogs.service';
import { AuditLogsModel } from '../model/auditlogs/auditlogsModel';
import { GlobalService } from '../shared/service/global.service';
import { CanActivate, Router } from '@angular/router';

var sidebarTimer;

// The Moving Tab (the element that is moving on the sidebar, when you switch the pages) is depended on jQuery because it is doing a lot of calculations and changes based on Bootstrap collapse elements. If you have a better suggestion please send it to hello@creative-tim.com and we would be glad to talk more about this improvement. Thank you!
// var mda: any = {
//   movingTab: '<div class="sidebar-moving-tab"/>',
//   isChild: false,
//   sidebarMenuActive: '',
//   movingTabInitialised: false,
//   distance: 0,
//   $currentActive: '',

//   setMovingTabPosition: function (curActive) {

//     var $currentActive = mda.sidebarMenuActive;
//     mda.distance = $currentActive.parent().position().top - 10;

//     if ($currentActive.closest('.collapse').length != 0) {
//       var parent_distance = $currentActive.closest('.collapse').parent().position().top;
//       mda.distance = mda.distance + parent_distance;
//     }

//   setMovingTabPosition: function ($currentActive) {

//     var $currentActive = mda.sidebarMenuActive;
//     mda.distance = $currentActive.parent().position().top - 10;

//     if ($currentActive.closest('.collapse').length != 0) {
//       var parent_distance = $currentActive.closest('.collapse').parent().position().top;
//       mda.distance = mda.distance + parent_distance;
//     }

//     mda.moveTab();
//   },
//   initMovingTab: function () {
//     mda.movingTab = $(mda.movingTab);

//     mda.sidebarMenuActive = $('.sidebar .nav-container > .nav > li.active > a:not([data-toggle="collapse"]');

//     if (mda.sidebarMenuActive.length != 0) {
//       mda.setMovingTabPosition(mda.sidebarMenuActive);
//     } else {
//       mda.sidebarMenuActive = $('.sidebar .nav-container .nav > li.active .collapse li.active > a');
//       mda.isChild = true;
//       this.setParentCollapse();
//     }

//     mda.sidebarMenuActive.parent().addClass('visible');

//     var button_text = mda.sidebarMenuActive.html();
//     mda.movingTab.html(button_text);

//     $('.sidebar .nav-container').append(mda.movingTab);

//     if (window.history && window.history.pushState) {
//       $(window).on('popstate', function () {

//         setTimeout(function () {
//           mda.sidebarMenuActive = $('.sidebar .nav-container .nav li.active a:not([data-toggle="collapse"])');

//           if (mda.isChild) {
//             this.setParentCollapse();
//           }
//           clearTimeout(sidebarTimer);

//           var $currentActive = mda.sidebarMenuActive;

//           $('.sidebar .nav-container .nav li').removeClass('visible');

//           var $movingTab = mda.movingTab;
//           $movingTab.addClass('moving');

//           $movingTab.css('padding-left', $currentActive.css('padding-left'));
//           var button_text = $currentActive.html();

//           mda.setMovingTabPosition($currentActive);

//           sidebarTimer = setTimeout(function () {
//             $movingTab.removeClass('moving');
//             $currentActive.parent().addClass('visible');
//           }, 650);

//           setTimeout(function () {
//             $movingTab.html(button_text);
//           }, 10);
//         }, 10);

//       });
//     }

//     $('.sidebar .nav .collapse').on('hidden.bs.collapse', function () {
//       var $currentActive = mda.sidebarMenuActive;

//       mda.distance = $currentActive.parent().position().top - 10;

//       if ($currentActive.closest('.collapse').length != 0) {
//         var parent_distance = $currentActive.closest('.collapse').parent().position().top;
//         mda.distance = mda.distance + parent_distance;
//       }

//       mda.moveTab();
//     });

//     $('.sidebar .nav .collapse').on('shown.bs.collapse', function () {
//       var $currentActive = mda.sidebarMenuActive;

// $('.sidebar .nav-container .nav > li > a:not([data-toggle="collapse"])').click(function () {
//   var $currentActive = null;

//   mda.sidebarMenuActive = $(this);
//   //var $parent = $(this).parent();

//       if ($currentActive.closest('.collapse').length != 0) {
//         var parent_distance = $currentActive.closest('.collapse').parent().position().top;
//         mda.distance = mda.distance + parent_distance;
//       }

//       mda.moveTab();
//     });

//$currentActive = mda.sidebarMenuActive;

//       if (mda.sidebarMenuActive.closest('.collapse').length == 0) {
//         mda.isChild = false;
//       }

//       // we call the animation of the moving tab
//       clearTimeout(sidebarTimer);

//       var $currentActive = mda.sidebarMenuActive;

// $currentActive = mda.sidebarMenuActive;
// mda.distance = $currentActive.parent().position().top - 10;

//       var $movingTab = mda.movingTab;
//       $movingTab.addClass('moving');

//       $movingTab.css('padding-left', $currentActive.css('padding-left'));
//       var button_text = $currentActive.html();

//       var $currentActive = mda.sidebarMenuActive;
//       mda.distance = $currentActive.parent().position().top - 10;

//       if ($currentActive.closest('.collapse').length != 0) {
//         var parent_distance = $currentActive.closest('.collapse').parent().position().top;
//         mda.distance = mda.distance + parent_distance;
//       }

//       mda.moveTab();

//       sidebarTimer = setTimeout(function () {
//         $movingTab.removeClass('moving');
//         $currentActive.parent().addClass('visible');
//       }, 650);

//       setTimeout(function () {
//         $movingTab.html(button_text);
//       }, 10);
//     });
//   },
//   setParentCollapse: function () {
//     if (mda.isChild) {
//       var $sidebarParent = mda.sidebarMenuActive.parent().parent().parent();
//       var collapseId = $sidebarParent.siblings('a').attr("href");

//       $(collapseId).collapse("show");

//       $(collapseId).collapse()
//         .on('shown.bs.collapse', () => {
//           mda.setMovingTabPosition();
//         })
//         .on('hidden.bs.collapse', () => {
//           mda.setMovingTabPosition();
//         });
//     }
//   },
//   animateMovingTab: function () {
//     clearTimeout(sidebarTimer);

//     var $currentActive = mda.sidebarMenuActive;

//     $('.sidebar .nav-container .nav li').removeClass('visible');

//     var $movingTab = mda.movingTab;
//     $movingTab.addClass('moving');

//     $movingTab.css('padding-left', $currentActive.css('padding-left'));
//     var button_text = $currentActive.html();

//     mda.setMovingTabPosition($currentActive);

//     sidebarTimer = setTimeout(function () {
//       $movingTab.removeClass('moving');
//       $currentActive.parent().addClass('visible');
//     }, 650);

//     setTimeout(function () {
//       $movingTab.html(button_text);
//     }, 10);
//   },
//   moveTab: function () {
//     mda.movingTab.css({
//       'transform': 'translate3d(0px,' + mda.distance + 'px, 0)',
//       '-webkit-transform': 'translate3d(0px,' + mda.distance + 'px, 0)',
//       '-moz-transform': 'translate3d(0px,' + mda.distance + 'px, 0)',
//       '-ms-transform': 'translate3d(0px,' + mda.distance + 'px, 0)',
//       '-o-transform': 'translate3d(0px,' + mda.distance + 'px, 0)'
//     });
//   }
// };


@Component({
  moduleId: module.id,
  selector: 'sidebar-cmp',
  templateUrl: 'sidebar.component.html',
  providers: [AuthService, GlobalService, AuditLogsService]
})

export class SidebarComponent implements OnInit {
  public menuItems: any[];
  tenantMenuItems: Observable<RouteInfo>;
  indLoading: boolean = false;
  msg: string;
  allConfig: any = null;
  subscription: Subscription;
  appVersion: string = '';
  appName: string = '';
  auditLogsModel: AuditLogsModel;
  userName: string = '';
  lstMenu: any = null;
  logType: AuditLogType = AuditLogType.Sidebar;
  pageTitleKey: any = 'SideBar';

        constructor(private _authService: AuthService,
          private appService: GlobalService,          
          private _auditLogsService: AuditLogsService,
          private router: Router) {
        }

  isNotMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }

  ngOnInit() {
    var $sidebar = $('.sidebar-wrapper');
    if (navigator.appVersion.indexOf("Win")!=-1) {
      $sidebar.perfectScrollbar();
    }
    try {
      this.subscription = this.appService.getAssignedRolesAndPermissions(this._authService.getUniqueID())
        .subscribe(datarow => {

          if (datarow.IsSuccess) {
                  this.lstMenu = datarow.Result;
                  window.localStorage.setItem("UserInfo", JSON.stringify(this.lstMenu));
                  var rolePermissions = this.lstMenu.RoleDTO.PermissionDataInGroupDTO.Menu;
                  if (rolePermissions != null && rolePermissions != undefined) {
                    this.menuItems = rolePermissions;
                    setTimeout(() => {
                      this.exapndMenuActive();
                    }, 300);
                    if (window.location.pathname.includes(GlobalSettings.homePageUrl)) {
                      this.appService.checkPermission(MainMenus.DASHBOARD, 'PageTitleKeys.DASHBOARD');
                    }
                  }
                  //this.appService.handleApiResponse(datarow, this.logType, AuditLogAction.Load, this.pageTitleKey);
                }
                else {                 
                  //this._authService.logout();
                  this.onLogoutUserShowAccessDeniedMsg();
                }
              });

            this.getAppVersion();
            this.getLoginUserName();
            //this.appService.handleApiResponse("{ 'ActionName' :'OnInit SideBar', 'SideBar':" + localStorage.getItem('LoggedInUser') + " }", this.logType, AuditLogAction.Load, this.pageTitleKey);
          }
          catch (ex) {
            this.appService.handleExceptions(ex);
          }
        }

        onUserLogout() {
          this._authService.logout();
        }

        onLogoutUserShowAccessDeniedMsg() {
          swal({
            title: this.appService.getTranslate('Message.Information.AccessDenied'),
            text: this.appService.getTranslate('Message.Information.NotAuthorized'),
            type: 'warning',
            showCancelButton: false,
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            buttonsStyling: false,
            reverseButtons: true,
            allowOutsideClick: false
          }).then(function (result) {
            if (result) {
              this._authService.logout();
            }
            else {
              this._authService.logout();
            }
            }.bind(this));         
        }

        exapndMenuActive(): any {
          $('ul.custom-nav li').each(function () {
            if ($(this).hasClass('active')) {
              $(this).parents(".child-menu").addClass("in");
            }
          });
        }       

  test() {
    // this.router.navigate(['/login']);

  }

  ngAfterViewInit() {
    // init Moving Tab after the view is initialisez
    // setTimeout(() => {
    //     if (!mda.movingTabInitialised) {
    //         mda.initMovingTab();
    //         mda.movingTabInitialised = true;
    //     }
    // }, 10);
  }

  getLoginUserName() {
    localStorage.setItem('LoggedInUser', this._authService.getName());
    this.userName = this._authService.getName();//.getItem("loginUserName");        
  }

  sidebarLogoutUser() {
    //this.appService.handleApiResponse("{ 'ActionName' :'Logout User', 'UserName':" + localStorage.getItem('LoggedInUser') + " }", this.logType, AuditLogAction.Logout, this.pageTitleKey);
    this.auditLogsModel = new AuditLogsModel();
    this.auditLogsModel.Logs = "{ 'ActionName' :'Logout User', 'UserName':" + localStorage.getItem('LoggedInUser') + " }";
    this.auditLogsModel.Type = "Logout User";
    this.subscription = this._auditLogsService.add(this.auditLogsModel).subscribe(
      result => {
      },
      error => {
      });
    localStorage.removeItem('auth_key');
    localStorage.removeItem('AppVersion');
    localStorage.removeItem('LoggedInUser');
    this._authService.logout();
  }

        getAppVersion() {
          if (this.allConfig == undefined || this.allConfig == null) {
            this.subscription = this.appService.getAppVersion().subscribe(item => {
              this.allConfig = item.appConfig;
              this.appVersion = this.allConfig[0].AppVersion;
              this.appName = this.allConfig[0].AppName;
              localStorage.setItem('AppVersion', this.appVersion);
              console.log('allConfig' + this.allConfig);
            });
          }
        }
      }
