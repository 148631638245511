import { Component, OnInit, Renderer, ViewChild, ElementRef, Directive } from '@angular/core';
import { ROUTES } from '../.././sidebar/sidebar-routes.config';
import { Router, ActivatedRoute } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { GlobalSettings } from '../../shared/global/globalsettings';
import { Subscription ,  Observable } from 'rxjs';
import { NavbarService } from './navbar.service';

import { FormBuilder, FormGroup, Validators, FormControl, NgForm } from '@angular/forms';
import { AuthService } from '../../pages/login/authservice';
import { AuditLogsService } from '../service/auditLogs.service';
import { AuditLogsModel } from '../../model/auditlogs/auditlogsModel';

//import { translateDictModule } from '../../translateDict.module';
var misc:any ={
    navbar_menu_visible: 0,
    active_collapse: true,
    disabled_collapse_init: 0,
}
declare var $: any;
declare var swal: any;

@Component({
    moduleId: module.id,
    selector: 'navbar-cmp',
  templateUrl: 'navbar.component.html',
  providers: [AuthService, AuditLogsService]
})



export class NavbarComponent implements OnInit{
    private listTitles: any[];
    location: Location;
    private nativeElement: Node;
    private toggleButton;
    private sidebarVisible: boolean;
    indLoading: boolean = false;
    subscription: Subscription;
    public selectedValue: null;
    msg: "";
    lstTenant: Observable<any>;
    lstLanguage: any;
    SelectedLanguage: any;
    SelectedTenant: any;
    auditLogsModel: AuditLogsModel;

    @ViewChild("navbar-cmp") button;

    constructor(private _authService: AuthService, private _service: NavbarService, private _auditLogsService: AuditLogsService, location: Location, private renderer: Renderer, private element: ElementRef
       // , private _translate: translateDictModule
    ) {
        this.location = location;
        this.nativeElement = element.nativeElement;
        this.sidebarVisible = false;      
    }

    refresh(): void {

      this.indLoading = true;
        this.subscription = this._service.getTenants()
            .subscribe(datarow => {
                if (datarow.Status == null) {
                    this.lstTenant = datarow.Result;

                    //set default tenant
                    this.SelectedTenant = GlobalSettings.TENANT;
                    //var existingSelectedTenant = localStorage.getItem('SelectedTenant');
                    //if (existingSelectedTenant != null) {
                    //    this.SelectedTenant = existingSelectedTenant;
                    //}
                    //else {
                    //    this.SelectedTenant = this.lstTenant[2].ID;
                    //}
                    //GlobalSettings.TENANT = this.SelectedTenant;
                    //alert('tenant:' + GlobalSettings.TENANT);

                }
                if (datarow.Status == "Err" && datarow.ErrMessage != "") {
                    swal({
                        title: 'Error!',
                      //  text: this._translate.getTranslate(datarow.ErrMessage),
                        text: datarow.ErrMessage,
                        type: 'error',
                        timer: 5000,
                        showConfirmButton: false
                    })
                }
            },
            error => this.msg = error)

        this.subscription = this._service.getLanguages()
            .subscribe(datarow => {
                if (datarow.Status == null) {

                    this.lstLanguage = datarow.Result;

                    //set default tenant
                    this.SelectedLanguage = GlobalSettings.LANGUAGE;

                    //var existingSelectedLanguage = localStorage.getItem('SelectedLanguage');
                    //if (existingSelectedLanguage != null) {
                    //    this.SelectedLanguage = existingSelectedLanguage;
                    //}
                    //else {
                    //    this.SelectedLanguage = this.lstLanguage[2].ID;
                    //}
                    //GlobalSettings.LANGUAGE = this.SelectedLanguage;

                    //alert(localStorage.getItem('SelectedLanguage'));
                    //$('#SelectedLanguage').val(this.DropdownModel.SelectedLanguage);
                    //alert(this.selectedLanguage);

                    this._service.getLanguages()
                        .subscribe(datarow => {
                            if (datarow.Status == null) {
                                if ($(".selectpicker").length != 0) {
                                    $(".selectpicker").selectpicker();
                                }
                            } 
                        },
                        error => this.msg = error) 
                }
                if (datarow.Status == "Err" && datarow.ErrMessage != "") {
                    swal({
                        title: 'Error!',
                        //  text: this._translate.getTranslate(datarow.ErrMessage),
                        text: datarow.ErrMessage,
                        type: 'error',
                        timer: 5000,
                        showConfirmButton: false
                    })
                }
            },
            error => this.msg = error)
    }

    ngOnInit() {
        this.SelectedTenant = '';
        this.SelectedLanguage = '';
        this.listTitles = ROUTES.filter(listTitle => listTitle);

        if ($(".selectpicker").length != 0) {
            $(".selectpicker").selectpicker();
        }
       

        var navbar : HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
        if($('body').hasClass('sidebar-mini')){
            misc.sidebar_mini_active = true;
        }
        // $('#minimizeSidebar').click(function(){
        //     //var $btn = $(this);

        //     if(misc.sidebar_mini_active){
        //         $('body').removeClass('sidebar-mini');
        //         misc.sidebar_mini_active = false;

        //     }else{
        //         setTimeout(function(){
        //             $('body').addClass('sidebar-mini');

        //             misc.sidebar_mini_active = true;
        //         },300);
        //     }

        //     // we simulate the window Resize so the charts will get updated in realtime.
        //     var simulateWindowResize = setInterval(function(){
        //         window.dispatchEvent(new Event('resize'));
        //     },180);

        //     // we stop the simulation of Window Resize after the animations are completed
        //     setTimeout(function(){
        //         clearInterval(simulateWindowResize);
        //     },1000);
        // });



        this.refresh();

    }
    isMobileMenu(){
        if($(window).width() < 991){
            return false;
        }
        return true;
    }
    sidebarToggle(){
        var toggleButton = this.toggleButton;
        var body = document.getElementsByTagName('body')[0];

        if(!this.sidebarVisible){
            setTimeout(function(){
                toggleButton.classList.add('toggled');
            },500);
            body.classList.add('nav-open');
            this.sidebarVisible = true;
        } else {
            this.toggleButton.classList.remove('toggled');
            this.sidebarVisible = false;
            body.classList.remove('nav-open');
        }
    }

    getTitle() {
        
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if(titlee.charAt(0) === '#'){
            titlee = titlee.slice(2); 
           
        }
        for (var item = 0; item < this.listTitles.length; item++){
            
            if(this.listTitles[item].path === titlee){
                return this.listTitles[item].title;
            }
        }
        return 'Dashboard';
    }
    getPath(){
        // console.log(this.location);
        return this.location.prepareExternalUrl(this.location.path());
    }
    
    logoutUser() {
      this.auditLogsModel = new AuditLogsModel();
      this.auditLogsModel.Logs = "{ 'ActionName' :'Logout User', 'UserName':" + localStorage.getItem('LoggedInUser')+" }";
      this.auditLogsModel.Type = "Logout User";
      this.subscription = this._auditLogsService.add(this.auditLogsModel).subscribe(
        result => {
        },
        error => {
        });

      localStorage.removeItem('auth_key');
      localStorage.removeItem('AppVersion');
      localStorage.removeItem('LoggedInUser');
        //window.location.href = "../../pages/login";

      this._authService.logout();
     
    }

    onLanguageChange(langValue)
    { 
        GlobalSettings.LANGUAGE = langValue;
        GlobalSettings.BASE_API_ENDPOINT_TENANT = GlobalSettings.BASE_API_ENDPOINT + "api/" + GlobalSettings.LANGUAGE;
        console.log("GlobalSettings:BASE_API_ENDPOINT:- " + GlobalSettings.BASE_API_ENDPOINT);
        localStorage.setItem('SelectedLanguage', langValue);
        window.location.reload();
    }

    onTenentChange(tenantValue)
    {
        
        GlobalSettings.TENANT = tenantValue;
        GlobalSettings.TENANT_ID = tenantValue;
        
        GlobalSettings.HeaderString = {
            'Content-Type': 'application/json'
            , 'X-User-Tenant': GlobalSettings.TENANT
            , 'X-User-Token': localStorage.getItem('user_token')
            , 'X-User-ID': localStorage.getItem('user_id')
        };
        localStorage.setItem('SelectedTenant', tenantValue);
        window.location.reload();

        //GlobalSettings.HeaderString = "{'Content-Type': 'application/json', 'X-User-Tenant': " + GlobalSettings.TENANT + ", 'X-User-Token': " + localStorage.getItem('user_token') + ", 'X-User-ID': " + localStorage.getItem('user_id') + "}";

        //GlobalSettings.Header = new Headers({
        //    'Content-Type': 'application/json'
        //    , 'X-User-Tenant': GlobalSettings.TENANT
        //    , 'X-User-Token': localStorage.getItem('user_token')
        //    , 'X-User-ID': localStorage.getItem('user_id')
        //});
        //let body = JSON.stringify(GlobalSettings.Header);
        //console.log("GlobalSettings:Header:- " + body);
    } 
}
