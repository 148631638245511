import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AuthGuardService } from './auth-guard.service';
import { LoginComponent } from './pages/login/login.component';
export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: '',
    component: LoginComponent,
    pathMatch: 'full',
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './dashboard/dashboard.module#DashboardModule',
        canActivate: [AuthGuardService]
      },
      {
        path: 'schedules',
        loadChildren: './schedules/schedules.module#SchedulesModule',
        canActivate: [AuthGuardService]
      },
      {
        path: 'inspection',
        loadChildren: './inspection/inspection.module#InspectionModule',
        canActivate: [AuthGuardService]
      },
      {
        path: 'inspectionformsmanual',
        loadChildren: './inspectionforms/inspectionforms.module#InspectionFormsModule',
        canActivate: [AuthGuardService]
      },
      {
        path: 'inventory',
        loadChildren: './inventory/inventory.module#InventoryModule',
        canActivate: [AuthGuardService]
      },
      {
        path: 'formtype',
        loadChildren: './formtype/formtype.module#FormtypeModule',
        canActivate: [AuthGuardService]
      },
      {
        path: 'lookupkey',
        loadChildren: './lookupkey/lookupkey.module#LookupKeyModule',
        canActivate: [AuthGuardService]
      },
      {
        path: 'inspectionforms',
        loadChildren: './inspectionforms/inspectionforms.module#InspectionFormsModule',
        canActivate: [AuthGuardService]
      },
      {
        path: 'lookup',
        loadChildren: './lookup/lookup.module#LookupsModule',
        canActivate: [AuthGuardService]
      },
      {
        path: 'lookuplevel',
        loadChildren: './lookuplevel/lookuplevel.module#LookupLevelModule',
        canActivate: [AuthGuardService]
      },
      //{
      //    path: 'notification',
      //  loadChildren: './notification/notification.module#NotificationModule',
      //  canActivate: [AuthGuardService]
      //}, 
      {
        path: 'asset',
        loadChildren: './asset/asset.module#AssetModule',
        canActivate: [AuthGuardService]
      },
      {
        path: 'category',
        loadChildren: './category/category.module#CategoryModule',
        canActivate: [AuthGuardService]
      },
      {
        path: 'config',
        loadChildren: './config/config.module#ConfigModule',
        canActivate: [AuthGuardService]
      },
      {
        path: 'devicetype',
        loadChildren: './devicetype/devicetype.module#DeviceTypeModule',
        canActivate: [AuthGuardService]
      },
      {
        path: 'language',
        loadChildren: './language/language.module#LanguageModule',
        canActivate: [AuthGuardService]
      },
      {
        path: 'resource',
        loadChildren: './resource/resource.module#ResourceModule',
        canActivate: [AuthGuardService]
      },
      {
        path: 'inspectionwidgets',
        loadChildren: './inspectionwidgets/inspectionwidgets.module#InspectionWidgetsModule',
        canActivate: [AuthGuardService]
      },
      {
        path: 'tenantmaster',
        loadChildren: './tenantmaster/tenantmaster.module#TenantmasterModule',
        canActivate: [AuthGuardService]
      },
      //{
      //    path: 'components',
      //  loadChildren: './components/components.module#ComponentsModule',
      //  canActivate: [AuthGuardService]
      //},
      //{
      //    path: 'forms',
      //  loadChildren: './forms/forms.module#Forms',
      //  canActivate: [AuthGuardService]
      //},
      //{
      //    path: 'tables',
      //  loadChildren: './tables/tables.module#TablesModule',
      //  canActivate: [AuthGuardService]
      //},
      //{
      //    path: 'maps',
      //  loadChildren: './maps/maps.module#MapsModule',
      //  canActivate: [AuthGuardService]
      //},
      //{
      //    path: 'widgets',
      //  loadChildren: './widgets/widgets.module#WidgetsModule',
      //  canActivate: [AuthGuardService]
      //},
      //{
      //    path: 'charts',
      //  loadChildren: './charts/charts.module#ChartsModule',
      //  canActivate: [AuthGuardService]
      //},
      //{
      //    path: 'calendar',
      //  loadChildren: './calendar/calendar.module#CalendarModule',
      //  canActivate: [AuthGuardService]
      //},
      {
        path: '',
        loadChildren: './userpage/user.module#UserModule',
        canActivate: [AuthGuardService]
      },
      //{
      //    path: '',
      //  loadChildren: './timeline/timeline.module#TimelineModule',
      //  canActivate: [AuthGuardService]
      //},
      {
        path: 'template',
        loadChildren: './template/template.module#TemplateModule',
        canActivate: [AuthGuardService]
      },
      {
        path: 'reportview',
        loadChildren: './reports/reportview/reportview.module#ReportViewModule',
        canActivate: [AuthGuardService]
      },
      {
        path: 'ovsrequests',
        loadChildren: './reports/ovsrequest/ovsrequests.module#OvsRequestsModule',
        canActivate: [AuthGuardService]
      },
      {
        path: 'ovsrequestbystatus',
        loadChildren: './reports/ovsrequestbystatus/ovsrequestbystatus.module#OvsRequestByStatusModule',
        canActivate: [AuthGuardService]
      },
      //{
      //  path: 'reportmeta',
      //  loadChildren: './reports/reportmeta/reportmeta.module#ReportMetaModule'
      //},
      {
        path: 'reportmetadata',
        loadChildren: './reports/reportmetadata/reportmetadata.module#ReportMetadataModule',
        canActivate: [AuthGuardService]
      },
      {
        path: 'rolepermissions',
        loadChildren: './rolepermissions/rolepermissions.module#RolePermissionsModule',
        canActivate: [AuthGuardService]
      },
      {
        path: 'userrolemapping',
        loadChildren: './userrolemapping/userrolemapping.module#UserRoleMappingModule',
        canActivate: [AuthGuardService]
      },
      {
        path: 'userinventorymapping',
        loadChildren: './userinventorymapping/userinventorymapping.module#UserInventoryMappingModule',
        canActivate: [AuthGuardService]
      },
      {
        path: 'auditlogs',
        loadChildren: './auditlogs/auditlogs.module#AuditLogsModule',
        canActivate: [AuthGuardService]
      },
      {
        path: 'helpdocument',
        loadChildren: './helpdocument/helpdocument.module#HelpDocumentModule',
        canActivate: [AuthGuardService]
      },
      {
        path: 'userpage',
        loadChildren: './userpage/user.module#UserModule',
       canActivate: [AuthGuardService]
      },
      {
        path: 'notificationconfig',
        loadChildren: './notifictionconfiguration/notifictionconfiguration.module#NotifictionconfigurationModule',
        canActivate: [AuthGuardService]

      },
      {
        path: 'reportreceiverlist',
        loadChildren: './reportreceiverlist/reportreceiverlist.module#ReportReceiverListModule',
        canActivate: [AuthGuardService]

      },
      {
        path: 'downloadreport',
        loadChildren: './reports/downloadreport/downloadreport.module#DownloadReportModule',
        canActivate: [AuthGuardService]
      },
    ]
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [{
      path: 'pages',
      loadChildren: './pages/pages.module#PagesModule'
    }],
    //canActivateChild: [AuthGuardService]
  }
];
