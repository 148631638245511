import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SidebarComponent } from './sidebar.component';
import { NavbarModule } from '../shared/navbar/navbar.module';

@NgModule({
  imports: [RouterModule, CommonModule, NavbarModule],
    declarations: [SidebarComponent],
    exports: [SidebarComponent]
})

export class SidebarModule {}
