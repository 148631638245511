import { GlobalSettings,DateFormat, TimeFormat } from '../../shared/global/globalsettings';
declare var moment: any; 

export class CustomDateTimesModel {
    ScheduleDate: any;
    ScheduleTime: string;
    DueDate: any;
    DueTime: string;

  //local date to utc date
    getUTCDate(localDate): number {
        try {
            var year = -1;
            var month = -1;
            var day = -1;
          var all = null;

          switch (GlobalSettings.DATE_FORMAT) {
            case DateFormat.DDMMYYYY:
            case DateFormat.ddMMyyyy:
                    all = localDate.split("/");
                    year = all[2];
                    month = all[1];
                    day = all[0];
                    break;
            case DateFormat.MMddyyyy:
            case DateFormat.MMDDYYYY:
                    all = localDate.split("/");
                    year = all[2];
                    month = all[0];
                    day = all[1];
                    break;
                default:
            }

            if (year != -1 && month != -1 && day != -1) {
              var date = new Date(year, month - 1, day);
                //alert(date);
                var result = Math.round(date.getTime() / 1000.0);
                //alert( this.getLocalDate(result));
                return result;
            }
            else return 0;
        }
        catch (ex) { }
        return 0;
    }

  //utc date to local date using format
    getLocalDate(utcDate): string {
        try {
            var myDate = new Date(utcDate * 1000);
            var dt = new moment(myDate);
          var targetDate = dt.format(GlobalSettings.DATE_FORMAT);
            return targetDate;
        }
        catch (ex) { }
        return '-';
    }

  //utc date to local date
    getLocalDateToSetValueInDatePicker(utcDate): string {
        try {
            var myDate = new Date(utcDate * 1000);
            return myDate.toString(); 
        }
        catch (ex) { }
        return '-';
    }
    
// Method is used to get DateTime Picker date from UTC date
    getDatePickerDateFromUTC(utcDate): Date {
        try {
            var myDate = new Date(utcDate * 1000);
            return myDate; 
        }
        catch (ex) { }
        return new Date();
    }

  //local time to utc time
    getUTCTime(localTime): number {
        try {
            var hour = -1;
            var min = -1;
            var sec = -1;
            var milisec = -1;
            var AmPm = '-';
            var all = null;
            var defDate = null;
            var defDate2 = null;
            var result = null;

          switch (GlobalSettings.TIME_FORMAT) {
            case TimeFormat.hhmma:
                    all = localTime.split(":");
                    var amPm = all[1].split(" ");
                    if (amPm[1] == "AM") {
                        hour = all[0];
                        min = amPm[0];
                    }
                    else if (amPm[1] == "PM") {
                        hour = parseInt(all[0]) + 12;
                        min = amPm[0];
                    } 
                    if (hour != -1 && min != -1) {
                        defDate = new Date();
                        defDate2 = new Date(defDate.getFullYear(), defDate.getMonth(), defDate.getDate(),hour, min);
                        result = Math.round(defDate2.getTime() / 1000.0);
                        return result;
                    }
              break;
            case TimeFormat.HHmm:
                    all = localTime.split(":");
                    hour = all[0];
                    min = all[1]; 
                    if (hour != -1 && min != -1) {
                        defDate = new Date();
                        defDate2 = new Date(defDate.getFullYear(), defDate.getMonth(), defDate.getDate(), hour, min);
                        result = Math.round(defDate2.getTime() / 1000.0);
                        return result;
                    }
                    break;
                default:
            } 
        }
        catch (ex) { }
        return 0;
    }

  //UTC date to local format date
    getLocalTimeToSetValueInTimePicker(utcTime): string {
        try {
            var myDate = new Date(utcTime * 1000);
            return myDate.toString();
        }
        catch (ex) { alert('eer: '+ex); }
        return '-';
  }

  //UTC time to local format time
    getLocalTime(utcTime): string {
        try {
            var myDate = new Date(utcTime * 1000);
            var dt = new moment(myDate);
          return dt.format(GlobalSettings.TIME_FORMAT);
        }
        catch (ex) { alert('eer: ' + ex); }
        return '-';
  }

  //get date (date format) from local date (string format)
  getDateFromLocalDate(localDate): Date {
    var year = -1;
    var month = -1;
    var day = -1;
    var all = null;

    switch (GlobalSettings.DATE_FORMAT) {
      case DateFormat.DDMMYYYY:
      case DateFormat.ddMMyyyy:
        all = localDate.split("/");
        year = all[2];
        month = all[1];
        day = all[0];
        break;
      case DateFormat.MMddyyyy:
        case DateFormat.MMDDYYYY:
        all = localDate.split("/");
        year = all[2];
        month = all[0];
        day = all[1];
        break;
      default:
    }
    if (year != -1 && month != -1 && day != -1) {
      var date = new Date(year, month - 1, day);
      return date;
    }
    return new Date(1, 1, 1);
  }

  //get utc date of provided date
  getDateToUTC(date: Date): string {
    var result = Math.round(date.getTime() / 1000.0);
    return result.toString();
  }

  //get start date of provided date
  getStartDateToUTC(date: Date): string {
    var dateTemp = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    var result = this.getDateToUTC(dateTemp); 
    return result.toString();
  }

  //get start date of 'localDate' (string format) by adding 'daysToAdd'
  getUTCUpdatedDate(localDate: string, daysToAdd): string {
    try {
      let dt = this.getDateFromLocalDate(localDate);
      dt.setDate(dt.getDate() + daysToAdd);
      let result = this.getDateToUTC(dt);
      return result;
    }
    catch (ex) { alert('eer: ' + ex); }
    return "0";
  }

  //get start date of 'localDate' (date format) by adding 'daysToAdd'
  getStartUTCUpdatedDate(localDate: Date, daysToAdd): string {
    try {
      let sameDate = new Date(localDate.getFullYear(), localDate.getMonth(), localDate.getDate());
      sameDate.setDate(sameDate.getDate() + daysToAdd);
      let result = this.getDateToUTC(sameDate);
      return result;
    }
    catch (ex) { alert('eer: ' + ex); }
    return "0";
  }

  //get todays start date in utc
  getTodaysStartDateInEpoch(): string {
    try {
      let todaysAllDate = new Date();
      var date = new Date(todaysAllDate.getFullYear(), todaysAllDate.getMonth(), todaysAllDate.getDate());
      var result = this.getDateToUTC(date);
      return result;
    }
    catch (ex) { alert('eer: ' + ex); }
    return '1';
  }

  //get current date in utc
  getCurrentDateInEpoch(): string {
    try {
      let todaysAllDate = new Date();
      var result = this.getDateToUTC(todaysAllDate);
      return result;
    }
    catch (ex) { alert('eer: ' + ex); }
    return '99999999999';
  }

  //get current month start date in utc
  getThisMonthStartDateInEpoch(): string {
    try {
      let todaysAllDate = new Date();
      var date = new Date(todaysAllDate.getFullYear(), todaysAllDate.getMonth(), 1);
      var result = this.getDateToUTC(date);
      return result;
    }
    catch (ex) { alert('eer: ' + ex); }
    return '1';
  }

}
