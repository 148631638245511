// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export enum DateFormat {
  DDMMYYYY = "DD/MM/YYYY",
  ddMMyyyy = "dd/MM/yyyy",
  MMddyyyy = "MM/dd/yyyy",
  MMDDYYYY = "MM/DD/YYYY"
}

export enum TimeFormat {
  HHmm = "HH:mm",
  hhmma = "hh:mm a"
}

export enum DateTimeFormat {
  ddMMyyyyHHmm = "dd/MM/yyyy HH:mm",
  MMddyyyyHHmm = "MM/dd/yyyy HH:mm",
  ddMMyyyyhhmma = "dd/MM/yyyy hh:mm a",
  MMddyyyyhhmma = "MM/dd/yyyy hh:mm a"
}

export const environment = {
  production: false,
  Settings: {
    WEB_TARGET_API: "https://remarketinginspectionsapi.azurewebsites.net/",
    APPLICATION_INSIGHTS_INSTRUMENTATION_KEY: "53427900-63c1-4e34-95a1-c95a0ec8adc9",
    WEB_LANGUAGE: "en",
    WEB_TITLE: "General Motors",
    DATE_FORMAT_FOR_DATEPICKER: DateFormat.MMDDYYYY,
    DATE_FORMAT: DateFormat.MMddyyyy,
    DATE_FORMAT_PIPE: DateTimeFormat.MMddyyyyHHmm,
    TIME_FORMAT: TimeFormat.HHmm,
    AP_TENANT_ID: "0798b469-e451-4278-aa41-a2cb4facb16d",
    I18N_LANGUAGE: "en",
    AllowEditSubmittedInspection: false,
    BASEAPI: window.location.origin + "/api", // .NetCore2.0 WebAPI 
    AUTHORITY: "https://login.microsoftonline.com/e4eb52e0-fc62-43be-8eae-3e83348d0d40",
    RESPONSE_TYPE: 'id_token',
    RESPONSE_MODE: 'id_token',
    STATE: '',
    MS_GRAPH_URI: 'https://graph.microsoft.com/',
    REDIRECT_URI: window.location.origin,
    POST_LOGOUT_REDIRECT: window.location.origin + "/pages/login",
    MSGRAPH_BETA_API: 'https://graph.microsoft.com/beta/',
    MSGRAPH_v1_API: 'https://graph.microsoft.com/v1.0/',
    SCOPES: ['openid'],
    ADMIN_CONSENT: true,
    Version: '3.8.1.1',
    //GM Production Ad
    CLIENT_ID: "32c079ac-8648-4b80-bbde-8366ca26fd17",
    TENANT: "remarketinginspections.com", //"dmigmazuread.onmicrosoft.com",
    TENANT_ID: "f09736c9-7691-479e-b246-d2868396c030",
    USER_TOKEN_ID:"7AEC7E0A-746C-4253-8752-B5AEB23CCB23"
  }
};
