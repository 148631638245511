import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Http, HttpModule } from '@angular/http';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule,Router, NavigationEnd } from '@angular/router';
import { UserIdleModule } from 'angular-user-idle';
//new
import { TranslateLoader, TranslateModule, TranslateStaticLoader } from 'ng2-translate';
import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';
import { ApplicationInsightsService } from './application-insights.service';
import { AuthGuardService } from './auth-guard.service';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AuthService } from './pages/login/authservice';
import { LoginComponent } from './pages/login/login.component';
import { PagerService } from './services/index';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule } from './shared/navbar/navbar.module';
import { AuditLogsService } from './shared/service/auditLogs.service';
import { GlobalService } from './shared/service/global.service';
import { SidebarModule } from './sidebar/sidebar.module';






@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        RouterModule.forRoot(AppRoutes),
        HttpModule,
        SidebarModule,
        NavbarModule,
        FooterModule, 
        // Optionally you can set time for `idle`, `timeout` and `ping` in seconds.
        // Default values: `idle` is 600 (10 minutes), `timeout` is 300 (5 minutes) 
        // and `ping` is 120 (2 minutes).
      UserIdleModule.forRoot({ idle: 3300, timeout: 300, ping: 120 }),
      TranslateModule.forRoot({
        provide: TranslateLoader,
        useFactory: (http: Http) => new TranslateStaticLoader(http, './assets/i18n', '.json'),
        deps: [Http]
      })
    ],
    declarations: [
        AppComponent,
        AdminLayoutComponent,
      AuthLayoutComponent,
      LoginComponent
  ],
  providers: [PagerService, AuthGuardService, AuthService, AuditLogsService, GlobalService, ApplicationInsightsService],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { 
  constructor(private router: Router) {
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) { 
       // console.log("change");
       setTimeout(function(){
        const container = document.querySelector('.main-panel');
        container.scrollTop = 0;
      },500);
      }
    });
  }
}
