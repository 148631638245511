import { Injectable, OnInit } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ActivatedRouteSnapshot, ResolveEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { environment } from "../environments/environment";

@Injectable()
export class ApplicationInsightsService {
  private routerSubscription: Subscription;
 
  private appInsights = new ApplicationInsights({
    config: {
      instrumentationKey:environment.Settings.APPLICATION_INSIGHTS_INSTRUMENTATION_KEY,
      autoTrackPageVisitTime: true,
      url: environment.Settings.WEB_TARGET_API,
      appId: this.router.url,
      //extensions: { enableAutoRouteTracking:true}
    }   
  });


  constructor(private router: Router) {
    this.appInsights.loadAppInsights();
    this.routerSubscription = this.router.events.pipe(filter(event => event instanceof ResolveEnd)).subscribe((event: ResolveEnd) => {
      const activatedComponent = this.getActivatedComponent(event.state.root);
      if (activatedComponent) {
        this.logPageView(`${activatedComponent.name} ${this.getRouteTemplate(event.state.root)}`, event.urlAfterRedirects);
      }
    });
  }

  setUserId(userId: string) {
    this.appInsights.setAuthenticatedUserContext(userId);
  }

  clearUserId() {
    this.appInsights.clearAuthenticatedUserContext();
  }

  logPageView(name?: string, uri?: string) {
    this.appInsights.trackPageView({ name, uri });
  }

  logPageViewPerformance(name?: string, uri?: string) {
    this.appInsights.trackPageViewPerformance({ name, uri });
  }

  logEvent(name: string, properties?: { [key: string]: string }, measurements?: { [key: string]: number }) {
    this.appInsights.trackEvent({ name : name}, this.AddGlobalProperties(properties));
  }

  logTrace(message: string, properties?: { [key: string]: string }, measurements?: { [key: string]: number }) {
    this.appInsights.trackTrace({ message : message }, this.AddGlobalProperties(properties));
  }

  logError(error: Error, properties?: { [key: string]: string }, measurements?: { [key: string]: number }) {
    this.appInsights.trackException({ error: error });
  }  

  private AddGlobalProperties(properties?: { [key: string]: string }): { [key: string]: string } {
    if (!properties) {
      properties = {};
    }

    //add your custom properties such as app version

    return properties;
  }

  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }

    return snapshot.component;
  }

  private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
    let path = '';
    if (snapshot.routeConfig) {
      path += snapshot.routeConfig.path;
    }

    if (snapshot.firstChild) {
      return path + this.getRouteTemplate(snapshot.firstChild);
    }

    return path;
  }

  //
//  appInsights.trackEvent({ name: 'some event'});
//  appInsights.trackPageView({ name: 'some page'});
//  appInsights.trackPageViewPerformance({ name: 'some page', url: 'some url'});
//  appInsights.trackException({ error: new Error('some error')});
//appInsights.trackTrace({ message: 'some trace' });
//appInsights.trackMetric({ name: 'some metric', average: 42 });
//appInsights.trackDependencyData({ absoluteUrl: 'some url', resultCode: 200, method: 'GET', id: 'some id' });
//appInsights.startTrackPage("pageName");
//appInsights.stopTrackPage("pageName", { customProp1: "some value" });
//appInsights.startTrackEvent("event");
//appInsights.stopTrackEvent("event", { customProp1: "some value" });
//appInsights.flush();
}
