import { Component, OnInit } from '@angular/core';
import { Params, Router } from '@angular/router'
import { AuthService } from './authservice';
import { AuditLogsService } from '../../shared/service/auditLogs.service';
import { AuditLogsModel } from '../../model/auditlogs/auditlogsModel';
import { Subscription } from 'rxjs';

@Component({
  moduleId: module.id,
  selector: 'login-cmp',
  templateUrl: './login.component.html',
  providers: [AuthService, AuditLogsService]
})

export class LoginComponent implements OnInit {
  auditLogsModel: AuditLogsModel;
  subscription: Subscription;

  constructor(private router: Router,
    private _AuthService: AuthService,
    private _auditLogsService: AuditLogsService) {
  } 
  
  ngOnInit()
  {
    if (this._AuthService.isOnline())
    {      
      this.router.navigate(['/dashboard']);      
    }
    else
    { 
      this.login();     
    }
  }

  login()
  {
    this._AuthService.login();
  }

  ngAfterViewInit() {
    console.log('on login afterviewinit');
    //var isUserLogin = this._AuthService.checkUserLoggedIn();
    //if (isUserLogin != null) {
      this.auditLogsModel = new AuditLogsModel();
      this.auditLogsModel.Logs = "{ 'ActionName' :'Login User', 'UserName':"+ this._AuthService.getName()+"}";
      this.auditLogsModel.Type = "Login User";
      this.subscription = this._auditLogsService.add(this.auditLogsModel).subscribe(
        result => {
        },
        error => {
        });
    //}
  }
}
