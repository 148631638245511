
import {catchError, tap, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions, Headers } from '@angular/http';
import { Observable, Subject } from 'rxjs';





import { GlobalSettings } from '../../shared/global/globalsettings';
//import { translateDictModule } from '../../translateDict.module';

//import { ResourceModel } from '../../model/Resource/ResourceModel';

declare var swal: any;
@Injectable()
export class NavbarService {
    constructor(private _http: Http
//, private _translate: translateDictModule
) { }

    private RegenerateData = new Subject<number>();
    // Observable string streams
    RegenerateData$ = this.RegenerateData.asObservable();

    public componentUrl = GlobalSettings.BASE_API_ENDPOINT_TENANT + "/asset";
    getHeader(): Headers {
        return new Headers(GlobalSettings.HeaderString);
    }

  getTenants(): Observable<any> {
    return this.getAPIResult("/lookup/tenants");
        //let headers = new Headers({ 'Authorization': 'Bearer ' + localStorage.getItem('auth_key') });
        //let options = new RequestOptions({ headers: this.getHeader() });

        ////return this._http.get( GlobalSettings.BASE_API_ENDPOINT + "api/en/lookup/tenants", options)
        //return this._http.get(GlobalSettings.BASE_API_ENDPOINT_TENANT + "/lookup/tenants", options)
        //    .map((response: Response) => response.json())
        //    .do(data => console.log("All: " + JSON.stringify(data)))
        //    .catch(this.handleErrorPromise);
    }

  getLanguages(): Observable<any> {
    return this.getAPIResult("/lookup/languages");
        //let headers = new Headers({ 'Authorization': 'Bearer ' + localStorage.getItem('auth_key') });
        //let options = new RequestOptions({ headers: this.getHeader() });

        //return this._http.get(GlobalSettings.BASE_API_ENDPOINT_TENANT + "/lookup/languages", options)
        //    .map((response: Response) => response.json())
        //    .do(data => console.log("All: " + JSON.stringify(data)))
        //    .catch(this.handleErrorPromise);
  }

  getAPIResult(apiName: string): Observable<any> {
    let options = new RequestOptions({ headers: this.getHeader() });
    return this._http.get(GlobalSettings.BASE_API_ENDPOINT_TENANT + apiName, options).pipe(
      map((response: Response) => response.json()),
      tap(data => console.log("All: " + JSON.stringify(data))),
      catchError(this.handleErrorPromise),);
  }

  

    protected handleErrorPromise(error: any): Promise<void> {
        try {
            error = JSON.parse(error._body);
        } catch (e) {
        }

        let errMsg = error.errorMessage
            ? error.errorMessage
            : error.message
                ? error.message
                : error._body
                    ? error._body
                    : error.status
                        ? `${error.status} - ${error.statusText}`
                       // : this._translate.getTranslate("Message.Error.error");
                        : "Message.Error.error";

        swal({
            title: "Error",
            text: errMsg,
            timer: GlobalSettings.SWEET_ALERT_TIMEOUT,
            showConfirmButton: false
        });
        return Promise.reject(errMsg);
    }
}
