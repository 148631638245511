import { environment } from "../../../environments/environment";

export enum DateFormat {
  DDMMYYYY = "DD/MM/YYYY",
  ddMMyyyy = "dd/MM/yyyy",
  MMddyyyy = "MM/dd/yyyy",
  MMDDYYYY = "MM/DD/YYYY"
}

export enum TimeFormat {
  HHmm = "HH:mm",
  hhmma = "hh:mm a"
}

export class GlobalSettings {

  static TITLE = environment.Settings.WEB_TITLE;
  static VERSION = environment.Settings.Version;
  static LANGUAGE = environment.Settings.WEB_LANGUAGE;
  static BASE_API_ENDPOINT = environment.Settings.WEB_TARGET_API;
  static BASE_API_ENDPOINT_TENANT = environment.Settings.WEB_TARGET_API + "api/" + GlobalSettings.LANGUAGE;
  static I18N_LANGUAGE = environment.Settings.I18N_LANGUAGE;
  static DATE_FORMAT = environment.Settings.DATE_FORMAT;
  static DATE_FORMAT_PIPE = environment.Settings.DATE_FORMAT_PIPE;
  static TIME_FORMAT = environment.Settings.TIME_FORMAT;
  static DATE_FORMAT_FOR_DATEPICKER = environment.Settings.DATE_FORMAT_FOR_DATEPICKER;

  static AllowEditSubmittedInspection = environment.Settings.AllowEditSubmittedInspection;

  static TENANT = environment.Settings.AP_TENANT_ID;
  static USER_TOKEN = environment.Settings.USER_TOKEN_ID; //698129B4-ABD3-491F-B273-CE2337165FA1"; //"7AEC7E0A-746C-4253-8752-B5AEB23CCB23";

  static Priority = 10;
  static levelCount = 7;
  static DATE_PICKER_LOCALE = 'en';
  static TENANT_ID = '1';
  static BASE_CURRENCY = 'MYR';
  static BASE_COUNTRY = 'EN';
  static AMOUNT_DECIMAL = 2;
  static AMOUNT_DECIMAL_SEPARATOR = '.';
  static AMOUNT_THOUSAND_SEPARATOR = ','
  static DATATABLE_TIMEOUT = 1000;
  static DATATABLE_TIMEOUT2 = 100;
  static SWEET_ALERT_TIMEOUT = 1000;
  static homePageUrl = '/dashboard';

  static HeaderString = {
    'Content-Type': 'application/json'
    , 'X-User-Tenant': GlobalSettings.TENANT
    , 'X-User-Token': GlobalSettings.USER_TOKEN // localStorage.getItem('user_token')
    , 'X-User-ID': GlobalSettings.getLoggedInUserInfo() //localStorage.getItem('user_id')
  };

  static EXPORT_REPORT_RECORDS = 500;
  static NO_OF_DAYS_LOGS = 5;

  static getLoggedInUserInfo(): string {
    var userInfo = JSON.parse(localStorage.getItem('UserInfo'));
    if (userInfo != null) {
      return userInfo.User.UserID;
    }
    else {
      return "";
    }
  }
}

export class CommonMethods {

  //TO DO: remove
  static writeConsoleLogs(log: string, logType: ConsoleLogType) {
    console.log("---------------------------- " + logType.toString() + " ----------------------------");
    console.log(log);
    console.log("---------------------------- " + logType.toString() + " ----------------------------");
  }

  //TO DO: remove
  static writeConsoleLogsForNullResult(methodName: string, auditLogAction: AuditLogAction, data: any) {
    console.log("---------------------------- " + ConsoleLogType.Error + " ----------------------------");
    console.log(methodName + "() => " + auditLogAction + " api returns null result for data : " + JSON.stringify(data));
    console.log("---------------------------- " + ConsoleLogType.Error + " ----------------------------");
  }

  //TO DO: remove
  static writeConsoleLogsForApiError(error: string, auditLogAction: AuditLogAction, data: any) {
    console.log("---------------------------- " + ConsoleLogType.Error + " ----------------------------");
    console.log("API Error => " + error + " in " + auditLogAction + " api for data : " + JSON.stringify(data));
    console.log("---------------------------- " + ConsoleLogType.Error + " ----------------------------");
  }

  //initilize popup scroll
  static initilizePopupScroll() {
    if (navigator.appVersion.indexOf("Win")!=-1) {
      $('.perfect-custom-scroll').perfectScrollbar();
    }

  }

  //show alert 
  static showAlert(message: string, alertType: AlertType = AlertType.Success, title: string = null) {
    let buttonClass = alertType == AlertType.Success ? "btn btn-success" :
      alertType == AlertType.Error ? "btn btn-danger" : "btn btn-warning";
    let thisTitle = title != null && title != "" ? title : alertType;
    swal({
      title: thisTitle,
      buttonsStyling: false,
      confirmButtonClass: buttonClass,
      allowOutsideClick: 0,
      html: message
    });
  }

  //TO DO: remove
  static showAlertForAddUpdateDelete(result: any) {
    let alertType = result.IsSuccess ? AlertType.Success : AlertType.Error;
    let message = result.IsSuccess ? result.DisplayMessage :
      (result.ErrorMessages == null || result.ErrorMessages.length == 0) ? result.DisplayMessage :
        result.ErrorMessages[0].Message;
    this.showAlert(message, alertType);
  }

  //show alert 
  static showMessageAlert(message: string, title: string) {
    let buttonClass = "btn btn-success";
    swal({
      title: title,
      buttonsStyling: false,
      confirmButtonClass: buttonClass,
      allowOutsideClick: 0,
      html: message
    });
  }

  //get deep copy of same object to prevent binding
  static GetDeepCopy(model: any) {
    let deepModel = JSON.parse(JSON.stringify(model));
    return deepModel;
  }

  //write logs
  static writeLogs(log: string, alertType: AlertType = AlertType.Message) {
    console.log("---------------------------- " + alertType.toString() + " ----------------------------");
    console.log(log);
    console.log("---------------------------- " + alertType.toString() + " ----------------------------");
  }

  //handle api response and disply messages according to response status
  static handleApiResponse(response: any, auditLogAction: AuditLogAction, successTitle: string = "",
    errorTitle: string = "", alertMessage: string = "") {
    if (response == null) {
      CommonMethods.writeLogs("'" + auditLogAction + "' returns null response", AlertType.Error);
    }
    else if (!response.IsSuccess) {
      CommonMethods.showAlert(CommonMethods.getErrorStringFromListOfErrors(response), AlertType.Error, errorTitle);
    }
    else if (auditLogAction != AuditLogAction.GetAll && auditLogAction != AuditLogAction.GetById) {
      let message = alertMessage != null && alertMessage != "" ? alertMessage :
        response.DisplayMessage == null ? "" : response.DisplayMessage;
      CommonMethods.showAlert(message, AlertType.Success, successTitle);
    }
  }

  //get strings from errors of string
  static getErrorStringFromListOfErrors(response: any) {
    if (response.ErrorMessages == null)
      return response.DisplayMessage;
    let errors = response.ErrorMessages.map(data => data.Message);
    return errors.toString();
  }

  //destroy existing datatable
  static destroyExistingDatatable(isRefresh: boolean, datatableId: string = "datatables") {
    try {
      if (isRefresh) {
        let table = $('#' + datatableId).DataTable();
        table.search('').columns().search('').draw();
        $('#' + datatableId).DataTable().destroy();
        $('#' + datatableId).DataTable = null;
      }
    } catch (e) {
    }
  }

  //datable initilization
  static initilizeDatatable(setting: any = null, datatableId: string = "datatables") {
    setTimeout(CommonMethods.AfterDataPopulated, GlobalSettings.DATATABLE_TIMEOUT2, datatableId, setting);
  }
  static AfterDataPopulated(datatableId: string, setting: any = null) {
    if (setting == null) {
      setting = {
        "pagingType": "full_numbers",
        "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
        language: {
          search: "_INPUT_",
          searchPlaceholder: "Search Records",
          paginate: {
            "first": '<em class="demo-icon iconprevious"></em>',
            "previous": '<em class="demo-icon iconsidebar_arrow"></em>',
            "next": '<em class="demo-icon iconsidebar_arrow rotate-180"></em>',
            "last": '<em class="demo-icon iconnext"></em>',
          }
        },
        stateSave: true,
        order: []
      };
    }
    let table = $('#' + datatableId).DataTable(setting);
    table.search('').draw();
  }

  //get current date in UTC
  static getCurrentDateInUTC(): number {
    try {
      var dateTemp = new Date();
      return this.getUTCDate(dateTemp);
    } catch (e) {
      CommonMethods.writeLogs(e, AlertType.Error);
    }
    return 0;
  }

  //utc number => date (Date format)
  static getDateFromUTC(utcnumber: any): Date {
    try {
      var myDate = new Date(utcnumber * 1000);
      return myDate;
    }
    catch (ex) { }
    return new Date();
  }

  //UTC time => local time (string format)
  static getLocalTimeFromUTC(utcTime: any): string {
    try {
      var myDate = new Date(utcTime * 1000);
      var dt = new moment(myDate, GlobalSettings.TIME_FORMAT, GlobalSettings.DATE_PICKER_LOCALE);
      return dt.format(GlobalSettings.TIME_FORMAT, GlobalSettings.DATE_PICKER_LOCALE);
    }
    catch (e) { CommonMethods.writeLogs(e, AlertType.Error); }
    return '';
  }

  // Date => UTC Start
  static getUTCStartDate(date: any): number {
    try {
      if (date == null || date== '')
        return null;

      var dateTemp = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      return this.getUTCDate(dateTemp);
    } catch (e) {
      CommonMethods.writeLogs(e, AlertType.Error);
    }
    return 0;
  }

  static getUTCEndDate(date: any): number {
    try {
      var dateTemp = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
      return this.getUTCDate(dateTemp);
    } catch (e) {
      CommonMethods.writeLogs(e, AlertType.Error);
    }
    return 0;
  }

  //Date => UTC
  static getUTCDate(date: any): number {
    try {
      return Math.round(date.getTime() / 1000.0);
    } catch (e) {
      CommonMethods.writeLogs(e, AlertType.Error);
    }
    return 0;
  }

  //Date => local date format string 
  public static getLocalDateFromDate(date: any): string {
    try {
      if (date != null) {
        var dateTemp = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        var dt = new moment(dateTemp);
        var targetDate = dt.format(GlobalSettings.DATE_FORMAT);
        return targetDate.toString();
      }
    } catch (e) {
    }
    return "";
  }

  //Date => updated date by number of days + -
  public static GetUpdateDate(date: any, numberOfDays: number): any {
    try {
      let dateTemp: Date;
      if (date != null) {
        dateTemp = new Date(date);
        dateTemp.setDate(date.getDate() + numberOfDays);
        return dateTemp;
      }
    } catch (e) {
    }
    return "";
  }

  // local time => UTC
  static getUTCFromLocalTime(localTime: any): number {
    try {
      var hour = -1;
      var min = -1;
      var sec = -1;
      var milisec = -1;
      var AmPm = '-';
      var all = null;
      var defDate = null;
      var defDate2 = null;

      switch (GlobalSettings.TIME_FORMAT) {
        case TimeFormat.hhmma:
          all = localTime.split(":");
          var amPm = all[1].split(" ");
          if (amPm[1] == "AM") {
            hour = all[0];
            min = amPm[0];
          }
          else if (amPm[1] == "PM") {
            hour = parseInt(all[0]) + 12;
            min = amPm[0];
          }
          if (hour != -1 && min != -1) {
            defDate = new Date();
            defDate2 = new Date(defDate.getFullYear(), defDate.getMonth(), defDate.getDate(), hour, min);
            return CommonMethods.getUTCDate(defDate2);
          }
          break;
        case TimeFormat.HHmm:
          all = localTime.split(":");
          hour = all[0];
          min = all[1];
          if (hour != -1 && min != -1) {
            defDate = new Date();
            defDate2 = new Date(defDate.getFullYear(), defDate.getMonth(), defDate.getDate(), hour, min);
            return CommonMethods.getUTCDate(defDate2);
          }
          break;
        default:
      }
    }
    catch (e) {
      CommonMethods.writeLogs(e, AlertType.Error);
    }
    return 0;
  }
}

export enum AlertType {
  Error = "Error",
  Warning = "Warning",
  Success = "Success",
  Message = "Message"
}

export enum ConsoleLogType {
  Error = "Error",
  Warning = "Warning",
  Message = "Message"
}

export enum AuditLogAction {
  GetAll = "Get All",
  GetById = "Get By Id",
  Add = "Add",
  Update = "Update",
  Delete = "Delete",
  Refresh = "Refresh",
  Save = "Save",

  CloneFormVersion = "Clone Form Version",
  SaveAssetTemplate = "Save Asset Template",
  SaveInspectionTemplate = "Save Inspection Template",
  UpdateUser = "Update User",
  SubFormClick = "Sub Form Click",
  PDFTemplateClick = "PDF Template Click",
  PDFDetails = "PDF Details",
  Details = "Details",
  Pagination = "Pagination",
  Load = "Onload",
  Logout = "Logout",
  File = "File Change",
  ResetPassword = "Reset Password"
}

export enum ActionMode {
  add = 'add',
  edit = 'edit'
}

export enum AuditLogType {
  All = "All",
  Asset = "Asset",
  Category = "Category",
  Config = "Config",
  DeviceType = "DeviceType",
  FormType = "FormType",
  Inspection = "Inspection",
  SubInspection = "Sub Inspection",
  FormVersion = "FormVersion",
  Widget = "Widget",
  Inventory = "Inventory",
  Language = "Language",
  Lookup = "Lookup",
  Report = "Report",
  Resource = "Resource",
  Role = "Role",
  Schedule = "Schedule",
  User = "User",
  UserInventoryMaping = "User Inventory Maping",
  UserRoleMapping = "User Role Mapping",
  Template = "Template",
  CoreComponents = "Core Components",
  FormVersionCode = "Form Version Code",
  Sidebar = "SideBar",
  Notification = "Notification",
  Dashboard = "Dashboard",
  DownloadReport = "DownloadReport"
}

export enum UserAction {
  Discard = "Discard",
  Save = "Save"
}

export enum FileFormat {
  Word = "Word",
  PDF = "PDF",
  Excel = "Excel"
}

export enum ActionButtons {
  ADD = "ADD",
  EDIT = "EDIT",
  DELETE = "DELETE",
  VIEW = "VIEW",
  REFRESH = "REFRESH",
  EXECUTE = "EXECUTE",
  READ = "READ",
  RESET ="RESET"
}

export enum MainMenus {
  DASHBOARD = "DASHBOARD",
  MASTER = "MASTER",
  APPLICATIONSETUP = "APPLICATIONSETUP",
  LOOKUP = "LOOKUP",
  INVENTORIES = "INVENTORIES",
  FORMS = "FORMS",
  INSPECTIONS = "INSPECTIONS",
  REPORTMETADATA = "REPORTMETADATA",
  MANAGEMENT = "MANAGEMENT",
  AUDITLOGS = "AUDITLOG",
  NOTIFICATIONS = "NOTIFICATIONS",
  REPORTRECEIVERLIST = "RECEIVERLIST",
  ODOVERIFICATION = "OdoVerification"
}
